import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import React from "react";

import { useSelector } from "react-redux";
import Navbar from "./components/commun/Navbar";
import Footer from "./components/commun/Footer";
import HomePage from "./pages/HomePage";

import LoginPage from "./pages/admin/LoginPage";
import OrdersPage from "./pages/order/OrdersPage";
import ProductPage from "./pages/product/ProductPage";

import DashboardPage from "./pages/admin/DashboardPage";
import PricingCalculator from "./pages/admin/PricingCalculator";
import AddProductPage from "./pages/product/AddProductPage";
import EditProductPage from "./pages/product/EditProductPage";
import ConfirmationPage from "./pages/order/ConfirmationPage";
import Facebook from "./tracking/Facebook";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <div data-theme="light" className="flex flex-col min-h-screen">
        <Navbar />
        <Facebook />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/p/:readableId" element={<ProductPage />} />

            <Route
              path="/confirmation/:orderId"
              element={<ConfirmationPage />}
            />

            <Route path="/login" element={<LoginPage />} />

            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/products/add"
              element={
                <PrivateRoute>
                  <AddProductPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/products/edit/:id"
              element={
                <PrivateRoute>
                  <EditProductPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <PrivateRoute>
                  <OrdersPage />
                </PrivateRoute>
              }
            />

            {/* PricingCalculator */}
            <Route
              path="admin/pricing-calculator"
              element={
                <PrivateRoute>
                  <PricingCalculator />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
