import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "../services/api";

// Helper function to handle errors consistently
const handleError = (error) => {
  console.error("API Error:", error);
  return (
    error.response?.data?.message ||
    error.message ||
    "An unknown error occurred"
  );
};

export const fetchProducts = createAsyncThunk(
  "products/fetchAll",
  async ({ page = 1, limit = 12 }, { rejectWithValue }) => {
    try {
      const { data } = await productService.getAll(page, limit);
      console.log("Received response:", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "products/fetchById",
  async (readableId, { rejectWithValue }) => {
    try {
      const { data } = await productService.getById(readableId);
      console.log("Received response:", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/add",
  async (productData, { rejectWithValue }) => {
    try {
      console.log("Sending product data:", productData);
      const { data } = await productService.add(productData);
      console.log("Received response:", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/update",
  async (productData, { rejectWithValue }) => {
    try {
      console.log("Sending product data:", productData);
      const { data } = await productService.update(productData);
      console.log("Received response  update:", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const hideProduct = createAsyncThunk(
  "products/hide",
  async (id, { rejectWithValue }) => {
    try {
      await productService.hide(id);
      return id;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

//view
export const viewProduct = createAsyncThunk(
  "products/view",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await productService.view(id);
      return data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const fetchMostViewedProducts = createAsyncThunk(
  "products/fetchMostViewed",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await productService.getMostViewed();
      console.log("Received most viewed products:", data);
      return data.products;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    list: [],
    currentProduct: null,
    loading: false,
    error: null,
    totalPages: 0,
    currentPage: 1,
    totalItems: 0,
    mostViewedProducts: [],
    loadingMostViewed: false,
    errorMostViewed: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearMostViewedError: (state) => {
      state.errorMostViewed = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload && Array.isArray(action.payload.products)) {
          if (action.payload.currentPage === 1) {
            state.list = action.payload.products;
          } else {
            state.list = [...state.list, ...action.payload.products];
          }
          state.totalPages = action.payload.totalPages;
          state.totalItems = action.payload.totalItems;
          state.currentPage = action.payload.currentPage;
        } else {
          console.error("Unexpected payload structure:", action.payload);
          state.error = "Received invalid data structure from the server";
        }
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchProductById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProduct = action.payload.product;
        state.productImages = action.payload.productImages;
        state.wilayasWithPricing = action.payload.wilayasWithPricing;
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex((p) => p.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(hideProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(hideProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter((p) => p.id !== action.payload);
      })
      .addCase(hideProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //view
      .addCase(viewProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(viewProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProduct = action.payload;
      })
      .addCase(viewProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMostViewedProducts.pending, (state) => {
        state.loadingMostViewed = true;
        state.errorMostViewed = null;
      })
      .addCase(fetchMostViewedProducts.fulfilled, (state, action) => {
        state.loadingMostViewed = false;
        state.mostViewedProducts = action.payload;
      })
      .addCase(fetchMostViewedProducts.rejected, (state, action) => {
        state.loadingMostViewed = false;
        state.errorMostViewed = action.payload;
      });
  },
});

export const { clearError, clearMostViewedError } = productSlice.actions;
export default productSlice.reducer;
