import React, { useEffect } from "react";

const Facebook = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '977111197432239');
      fbq('track', 'PageView');
    `;

    // Append the script to the document body
    document.body.appendChild(script);

    // Create a noscript element
    const noscript = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.src =
      "https://www.facebook.com/tr?id=1506055733349513&ev=PageView&noscript=1";
    noscript.appendChild(img);

    // Append the noscript to the document body
    document.body.appendChild(noscript);

    // Cleanup function
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null;
};

export default Facebook;
