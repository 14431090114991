import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchProducts } from "../../store/productSlice";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const {
    list: products,
    loading,
    error,
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">Manage Products</h2>
          <p className="text-gray-700">Click below to manage products.</p>
          <Link
            to="/admin/products/add"
            className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Go to Products
          </Link>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">View Orders</h2>
          <p className="text-gray-700">Click below to view orders.</p>
          <Link
            to="/admin/orders"
            className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            View Orders
          </Link>
        </div>
        <div className="bg-white shadow-md rounded p-6">
          <h2 className="text-xl font-semibold mb-4">View Orders</h2>
          <p className="text-gray-700">Click below to view orders.</p>
          <Link
            to="/admin/pricing-calculator"
            className="mt-4 inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Pricing Calculator
          </Link>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">
          Product List with Counters
        </h2>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">
            {error.message || "An error occurred while fetching products."}
          </p>
        ) : products.length === 0 ? (
          <p>No products available.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* {products.map((product) => (
              <div key={product.id} className="bg-white shadow-md rounded p-6">
                <h3 className="text-lg font-semibold mb-2">
                  {product.readableId}
                </h3>
                <p>Counter: {product.counter || 0}</p>
                <p>Price: {product.price} DA</p>
                <p className="truncate">Description: {product.description}</p>
              </div>
            ))} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
