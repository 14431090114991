import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductById, updateProduct } from "../../store/productSlice";
import { Input, Textarea, Button, Card, Alert } from "react-daisyui";

const EditProductPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentProduct: product, loading } = useSelector(
    (state) => state.products
  );

  const [formData, setFormData] = useState({
    price: "",
    description: "",
    images: [],
    colors: [],
  });
  const [colorInput, setColorInput] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(fetchProductById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (product) {
      setFormData({
        price: product.price,
        description: product.description,
        images: [],
        colors: product.colors ? JSON.parse(product.colors) : [],
      });
      // Set image previews if there are existing images
      if (product.productimages) {
        setImagePreviews(product.productimages.map((image) => image.imageLink));
      }
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevState) => ({
      ...prevState,
      images: files,
    }));

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
  };

  const handleAddColor = () => {
    const hexColor = colorInput.trim().replace(/^#/, "");
    if (/^[0-9A-F]{6}$/i.test(hexColor)) {
      setFormData((prevState) => ({
        ...prevState,
        colors: [...prevState.colors, hexColor],
      }));
      setColorInput("");
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter a valid 6-digit hex color code.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productData = new FormData();

    productData.append("price", formData.price);
    productData.append("description", formData.description);
    productData.append("colors", JSON.stringify(formData.colors));

    console.log("formData", formData);

    formData.images.forEach((image) => {
      productData.append(`images`, image);
    });

    //add old images
    product.productimages.forEach((image) => {
      productData.append(`images`, image.imageLink);
    });

    try {
      const resultAction = await dispatch(updateProduct({ id, productData }));
      if (updateProduct.fulfilled.match(resultAction)) {
        setSuccessMessage("Product updated successfully!");
        setErrorMessage("");
      } else {
        setErrorMessage(
          `Failed to update product: ${resultAction.error.message}`
        );
        setSuccessMessage("");
      }
    } catch (err) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage("");
    }
  };

  if (!product) return <div>Loading product...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Edit Product</h1>
      <Card>
        <Card.Body>
          {errorMessage && <Alert color="error">{errorMessage}</Alert>}
          {successMessage && <Alert color="success">{successMessage}</Alert>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="label" htmlFor="price">
                <span className="label-text">Price</span>
              </label>
              <Input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="label" htmlFor="description">
                <span className="label-text">Description</span>
              </label>
              <Textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="label" htmlFor="images">
                <span className="label-text">Images</span>
              </label>
              <Input
                type="file"
                id="images"
                name="images"
                onChange={handleImageChange}
                multiple
                accept="image/*"
              />
              <div className="flex flex-wrap mt-2">
                {imagePreviews.map((preview, index) => (
                  <img
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    className="w-24 h-24 object-cover m-1"
                  />
                ))}
              </div>
            </div>
            <div>
              <label className="label" htmlFor="colorInput">
                <span className="label-text">Colors</span>
              </label>
              <div className="flex space-x-2">
                <Input
                  type="text"
                  id="colorInput"
                  value={colorInput}
                  onChange={(e) => setColorInput(e.target.value)}
                  placeholder="Enter hex color code"
                />
                <Button type="button" onClick={handleAddColor}>
                  Add Color
                </Button>
              </div>
              <div className="flex flex-wrap mt-2">
                {formData.colors.map((color, index) => (
                  <div
                    key={index}
                    className="w-8 h-8 rounded-full mr-2 mb-2"
                    style={{ backgroundColor: `#${color}` }}
                  />
                ))}
              </div>
            </div>
            <Button type="submit" color="primary" loading={loading}>
              Update Product
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EditProductPage;
