import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrders,
  generateExcel,
  updateOrderStatus,
} from "../../store/orderSlice";
import { Button } from "react-daisyui";
import { FileSpreadsheet } from "lucide-react";
import OrderStats from "../../components/order/orders_view/OrderStats";
import OrderFilters from "../../components/order/orders_view/OrderFilters";
import OrderCard from "../../components/order/orders_view/OrderCard";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { list: orders, loading, error } = useSelector((state) => state.orders);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");
  const [dateRange, setDateRange] = useState("all");

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleGenerateExcel = () => {
    dispatch(generateExcel());
  };

  const handleStatusChange = (order, status, observations = "") => {
    dispatch(
      updateOrderStatus({
        id: order.id,
        status,
        observations,
      })
    );
  };

  const filteredAndSortedOrders = useMemo(() => {
    let filteredOrders = orders.filter(
      (order) =>
        (statusFilter === "All" || order.status === statusFilter) &&
        (order.readableId.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.phoneNumber.includes(searchTerm))
    );

    if (dateRange !== "all") {
      const now = new Date();
      const pastDate = new Date();
      switch (dateRange) {
        case "day":
          pastDate.setDate(now.getDate() - 1);
          break;
        case "week":
          pastDate.setDate(now.getDate() - 7);
          break;
        case "month":
          pastDate.setMonth(now.getMonth() - 1);
          break;
      }
      filteredOrders = filteredOrders.filter(
        (order) => new Date(order.createdAt) >= pastDate
      );
    }

    return filteredOrders.sort((a, b) => {
      if (sortBy === "date") {
        return sortOrder === "asc"
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      } else if (sortBy === "price") {
        return sortOrder === "asc"
          ? a.totalPrice - b.totalPrice
          : b.totalPrice - a.totalPrice;
      }
      return 0;
    });
  }, [orders, statusFilter, searchTerm, sortBy, sortOrder, dateRange]);

  const orderStats = useMemo(() => {
    const totalRevenue = filteredAndSortedOrders.reduce((sum, order) => {
      const price = Number(order.totalPrice);
      return isNaN(price) ? sum : sum + price;
    }, 0);
    const totalOrders = filteredAndSortedOrders.length;
    const averageOrderValue = totalOrders > 0 ? totalRevenue / totalOrders : 0;

    const statusCounts = filteredAndSortedOrders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {});

    const pieChartData = Object.entries(statusCounts).map(
      ([status, count]) => ({
        name: status,
        value: count,
      })
    );

    return {
      totalRevenue,
      totalOrders,
      averageOrderValue,
      pieChartData,
      formattedTotalRevenue: totalRevenue.toFixed(2),
      formattedAverageOrderValue: averageOrderValue.toFixed(2),
    };
  }, [filteredAndSortedOrders]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  if (error) return <div className="alert alert-error">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h1 className="text-3xl font-bold mb-4 md:mb-0">Orders Management</h1>
        <Button
          color="primary"
          onClick={handleGenerateExcel}
          startIcon={<FileSpreadsheet size={18} />}
        >
          Generate Excel
        </Button>
      </div>

      <OrderStats stats={orderStats} />

      <OrderFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAndSortedOrders.map((order) => (
          <OrderCard
            key={order.id}
            order={order}
            onStatusChange={handleStatusChange}
          />
        ))}
      </div>
    </div>
  );
};

export default OrdersPage;
