import React, { useState, useEffect } from "react";

const FloatingButton = ({ targetId }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        if (rect.top > window.innerHeight / 3 || rect.bottom < 0) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    toggleVisibility(); // Check visibility on mount

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [targetId]);

  const scrollToTarget = () => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button
      className={`fixed bottom-4 right-4 bg-purple-600 text-white p-3 rounded-full shadow-lg transition-opacity duration-300 animate-shake ${
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      onClick={scrollToTarget}
    >
      ! أكد الطلب الان
    </button>
  );
};

export default FloatingButton;
