import React, { useState } from "react";
import { Card, Select, Modal, Button } from "react-daisyui";

const OrderCard = ({ order, onStatusChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState(order.status);
  const [observations, setObservations] = useState("");

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setNewStatus(status);
    if (status === "Pending") {
      setShowModal(true);
    } else {
      onStatusChange(order, status);
    }
  };

  const handleSubmit = () => {
    onStatusChange(order, newStatus, observations);
    setShowModal(false);
  };

  return (
    <>
      <Card className="bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300">
        <Card.Body>
          <div className="flex justify-between items-start">
            <div>
              <Card.Title tag="h2" className="text-xl mb-2">
                Order #{order.readableId}
              </Card.Title>
              <span
                className={`badge badge-${order.status.toLowerCase()} mb-2`}
              >
                {order.status}
              </span>
            </div>
            <p className="text-sm text-gray-500">
              {new Date(order.createdAt).toLocaleDateString()}
              <br />
              {new Date(order.createdAt).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>

          <div className="mt-4">
            <p>
              <span className="font-semibold">Customer:</span>{" "}
              {order.customerName}
            </p>
            <p>
              <span className="font-semibold">Phone:</span> {order.phoneNumber}
            </p>
            <p>
              <span className="font-semibold">Address:</span> {order.address},
              Wilaya {order.wilaya}
            </p>
          </div>

          <div className="border-t border-gray-200 pt-4 mt-4">
            <h3 className="text-lg font-semibold mb-2">Order Details</h3>
            <div className="grid grid-cols-2 gap-2">
              <p>
                <span className="font-semibold">Total:</span> {order.totalPrice}{" "}
                DA
              </p>
              <p>
                <span className="font-semibold">Product:</span>{" "}
                {order.productPrice} DA
              </p>
              <p>
                <span className="font-semibold">Quantity:</span>{" "}
                {order.quantity}
              </p>
              <p>
                <span className="font-semibold">Delivery:</span>{" "}
                {order.optionPrice} DA
              </p>
              <p>
                <span className="font-semibold">Option:</span>{" "}
                {order.deliveryOption}
              </p>
              <div className="flex items-center">
                <span className="font-semibold mr-2">Color:</span>
                <div
                  className="rounded-full w-6 h-6 inline-block border border-gray-300"
                  style={{ backgroundColor: order.color }}
                ></div>
              </div>
            </div>
          </div>

          <Card.Actions className="justify-end mt-4">
            <Select
              value={order.status}
              onChange={handleStatusChange}
              className="select-bordered w-full max-w-xs"
            >
              <option value="Pending">Pending</option>
              <option value="Delivered">Delivered</option>
              <option value="Canceled">Canceled</option>
              <option value="Returned">Returned</option>
            </Select>
          </Card.Actions>
        </Card.Body>
      </Card>

      <Modal open={showModal} onClickBackdrop={() => setShowModal(false)}>
        <Modal.Header className="font-bold">
          Update to Pending Status
        </Modal.Header>
        <Modal.Body>
          <p>
            <span className="font-semibold">Order ID:</span> {order.readableId}
          </p>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Observations</span>
            </label>
            <textarea
              className="textarea textarea-bordered h-24"
              placeholder="Enter any additional observations"
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default OrderCard;
