import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { DollarSign, Package, TrendingUp, ShoppingCart } from "lucide-react";

const OrderStats = ({ stats }) => {
  const statusColors = {
    Pending: "#f0ad4e",
    Delivered: "#5cb85c",
    Canceled: "#d9534f",
    Returned: "#5bc0de",
  };

  const StatCard = ({ title, value, icon: Icon, description }) => (
    <div className="bg-white shadow rounded-lg p-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <Icon className="h-4 w-4 text-gray-400" />
      </div>
      <div className="text-2xl font-bold">{value}</div>
      <p className="text-xs text-gray-500 mt-1">{description}</p>
    </div>
  );

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Total Revenue"
          value={`${stats.formattedTotalRevenue} DA`}
          icon={DollarSign}
          description={`From ${stats.totalOrders} orders`}
        />
        <StatCard
          title="Average Order Value"
          value={`${stats.formattedAverageOrderValue} DA`}
          icon={Package}
          description="Per order"
        />
        <StatCard
          title="Conversion Rate"
          value={`${stats.conversionRate}%`}
          icon={TrendingUp}
          description={`${stats.totalOrders} orders from ${stats.totalVisits} visits`}
        />
        <StatCard
          title="Total Products Sold"
          value={stats.totalProductsSold}
          icon={ShoppingCart}
          description={`Avg ${stats.avgProductsPerOrder} per order`}
        />
      </div>
      <div className="bg-white shadow rounded-lg p-4">
        <h3 className="text-lg font-semibold mb-4">
          Order Status Distribution
        </h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={stats.pieChartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={({ name, percent }) =>
                `${name} ${(percent * 100).toFixed(0)}%`
              }
            >
              {stats.pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={statusColors[entry.name]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default OrderStats;
