import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../../store/productSlice";
import { Input, Textarea, Button, Card, Alert, Select } from "react-daisyui";
import {
  X,
  Plus,
  Bold,
  Italic,
  List,
  Image as ImageIcon,
  Trash2,
  Film,
  Camera,
} from "lucide-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ChromePicker } from "react-color";

const AddProductPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    mainImages: [],
    descriptionImages: [],
    gifs: [],
    videos: [],
    colors: [],
    modeQuantity: "quantity",
    sizes: "",
  });
  const [colorInput, setColorInput] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.products);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDescriptionChange = (content) => {
    setFormData((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);
    setFormData((prevState) => ({
      ...prevState,
      [type]: [...prevState[type], ...files],
    }));
  };

  const removeFile = (index, type) => {
    setFormData((prevState) => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index),
    }));
  };

  const handleColorChange = (color) => {
    setColorInput(color.hex);
  };

  const handleAddColor = () => {
    const hexColor = colorInput.replace(/^#/, "");
    if (/^[0-9A-F]{6}$/i.test(hexColor)) {
      setFormData((prevState) => ({
        ...prevState,
        colors: [...prevState.colors, hexColor],
      }));
      setColorInput("#000000");
      setShowColorPicker(false);
    } else {
      setErrorMessage("Please select a valid color. / يرجى اختيار لون صالح.");
    }
  };

  const removeColor = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      colors: prevState.colors.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productData = new FormData();

    Object.keys(formData).forEach((key) => {
      if (["mainImages", "descriptionImages", "gifs", "videos"].includes(key)) {
        formData[key].forEach((file) => {
          productData.append(key, file);
        });
      } else if (key === "colors") {
        productData.append(key, JSON.stringify(formData[key]));
      } else {
        productData.append(key, formData[key]);
      }
    });

    try {
      const resultAction = await dispatch(addProduct(productData));
      if (addProduct.fulfilled.match(resultAction)) {
        setSuccessMessage(
          "Product added successfully! / تمت إضافة المنتج بنجاح!"
        );
        setErrorMessage("");
        setFormData({
          name: "",
          price: "",
          description: "",
          mainImages: [],
          descriptionImages: [],
          gifs: [],
          videos: [],
          colors: [],
          modeQuantity: "quantity",
          sizes: "",
        });
        setColorInput("#000000");
      } else {
        setErrorMessage(
          `Failed to add product: ${resultAction.error.message} / فشل في إضافة المنتج: ${resultAction.error.message}`
        );
        setSuccessMessage("");
      }
    } catch (err) {
      setErrorMessage(
        "An error occurred. Please try again. / حدث خطأ. يرجى المحاولة مرة أخرى."
      );
      setSuccessMessage("");
    }
  };

  const renderFileUpload = (type, icon, label) => (
    <div>
      <label className="label">
        <span className="label-text font-semibold">{label}</span>
      </label>
      <div className="flex flex-wrap gap-2 mb-2">
        {formData[type].map((file, index) => (
          <div key={index} className="relative group">
            {type === "videos" ? (
              <video
                src={URL.createObjectURL(file)}
                className="w-24 h-24 object-cover rounded"
              />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt={`Preview ${index + 1}`}
                className="w-24 h-24 object-cover rounded"
              />
            )}
            <button
              type="button"
              onClick={() => removeFile(index, type)}
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X size={16} />
            </button>
          </div>
        ))}
      </div>
      <label className="btn btn-outline btn-primary w-full">
        <input
          type="file"
          onChange={(e) => handleFileChange(e, type)}
          multiple
          accept={
            type === "videos"
              ? "video/*"
              : type === "gifs"
              ? "image/gif"
              : "image/*"
          }
          className="hidden"
        />
        {icon}
        {label}
      </label>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Add Product / إضافة منتج
      </h1>
      <Card className="max-w-2xl mx-auto">
        <Card.Body>
          {errorMessage && (
            <Alert color="error" className="mb-4">
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert color="success" className="mb-4">
              {successMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="label" htmlFor="name">
                <span className="label-text font-semibold">
                  Product Name / اسم المنتج
                </span>
              </label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder="Enter product name / أدخل اسم المنتج"
                className="w-full border border-gray-300"
              />
            </div>
            <div>
              <label className="label" htmlFor="price">
                <span className="label-text font-semibold">Price / السعر</span>
              </label>
              <Input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                placeholder="Enter price / أدخل السعر"
                className="w-full border border-gray-300"
              />
            </div>
            <div>
              <label className="label" htmlFor="modeQuantity">
                <span className="label-text font-semibold">
                  Quantity Mode / نمط الكمية
                </span>
              </label>
              <Select
                id="modeQuantity"
                name="modeQuantity"
                value={formData.modeQuantity}
                onChange={handleChange}
                required
                className="w-full border border-gray-300"
              >
                <option value="quantity">Quantity Counter / عداد الكمية</option>
                <option value="choicesSame">
                  Same Choices (e.g., 1, 2, 3 units) / خيارات متماثلة (مثل: 1،
                  2، 3 وحدات)
                </option>
              </Select>
            </div>
            <div>
              <label className="label" htmlFor="sizes">
                <span className="label-text font-semibold">
                  Sizes / المقاسات
                </span>
              </label>
              <Input
                type="text"
                id="sizes"
                name="sizes"
                value={formData.sizes}
                onChange={handleChange}
                placeholder="Enter sizes (e.g., S,M,L,XL) / أدخل المقاسات (مثل: S,M,L,XL)"
                className="w-full border border-gray-300"
              />
            </div>
            <div>
              <label className="label" htmlFor="description">
                <span className="label-text font-semibold">
                  Description / الوصف
                </span>
              </label>
              <ReactQuill
                value={formData.description}
                onChange={handleDescriptionChange}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                placeholder="Enter product description / أدخل وصف المنتج"
                className="bg-white"
              />
            </div>
            {renderFileUpload(
              "mainImages",
              <ImageIcon size={20} className="mr-2" />,
              "Main Images / الصور الرئيسية"
            )}
            {renderFileUpload(
              "descriptionImages",
              <ImageIcon size={20} className="mr-2" />,
              "Description Images / صور الوصف"
            )}
            {renderFileUpload(
              "gifs",
              <Camera size={20} className="mr-2" />,
              "GIFs / الصور المتحركة"
            )}
            {renderFileUpload(
              "videos",
              <Film size={20} className="mr-2" />,
              "Videos / مقاطع الفيديو"
            )}
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Colors / الألوان
                </span>
              </label>
              <div className="flex space-x-2 mb-2">
                <div className="relative flex-grow">
                  <Input
                    type="text"
                    value={colorInput}
                    onChange={(e) => setColorInput(e.target.value)}
                    placeholder="Select color / اختر اللون"
                    className="w-full pl-10 border border-gray-300"
                    onClick={() => setShowColorPicker(true)}
                  />
                  <div
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 w-6 h-6 rounded-full border border-gray-300"
                    style={{ backgroundColor: colorInput }}
                  />
                  {showColorPicker && (
                    <div className="absolute z-10 mt-2">
                      <ChromePicker
                        color={colorInput}
                        onChange={handleColorChange}
                      />
                      <button
                        type="button"
                        onClick={() => setShowColorPicker(false)}
                        className="mt-2 w-full bg-gray-200 text-gray-800 py-1 rounded"
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
                <Button type="button" onClick={handleAddColor} color="primary">
                  Add / إضافة
                </Button>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.colors.map((color, index) => (
                  <div key={index} className="relative group">
                    <div
                      className="w-8 h-8 rounded-full border border-gray-300"
                      style={{ backgroundColor: `#${color}` }}
                    />
                    <button
                      type="button"
                      onClick={() => removeColor(index)}
                      className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Trash2 size={12} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <Button
              type="submit"
              color="primary"
              loading={loading}
              className="w-full"
            >
              {loading
                ? "Loading... / جار التحميل..."
                : "Add Product / إضافة المنتج"}
            </Button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddProductPage;
