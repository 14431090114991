import React from "react";
import { ShieldCheck, RotateCcw, Truck, Headphones } from "lucide-react";

const FeatureCard = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-4 bg-blue-50 rounded-lg shadow-sm">
    <div className="text-blue-500 mb-2">{icon}</div>
    <h5 className="font-semibold text-lg mb-2">{title}</h5>
    <p className="text-sm text-gray-600">{description}</p>
  </div>
);

const FeatureSection = () => (
  <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
    <h4 className="font-bold text-2xl mb-6 text-center text-blue-800">
      Pourquoi choisir ALGI-SHOP? / لماذا تختار ALGI-SHOP؟
    </h4>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      <FeatureCard
        icon={<ShieldCheck size={32} />}
        title="Produits authentiques / منتجات أصلية"
        description="Garantie 100% d'authenticité pour tous nos produits / ضمان 100٪ أصالة لجميع منتجاتنا"
      />
      <FeatureCard
        icon={<RotateCcw size={32} />}
        title="Retours faciles / إرجاع سهل"
        description="Politique de retour sans tracas sous 14 jours / سياسة إرجاع سهلة خلال 14 يومًا"
      />
      <FeatureCard
        icon={<Truck size={32} />}
        title="Paiement à la livraison / الدفع عند الاستلام"
        description="Payez en espèces à la réception de votre commande / ادفع نقدًا عند استلام طلبك"
      />
      <FeatureCard
        icon={<Headphones size={32} />}
        title="Support client / دعم العملاء"
        description="Assistance disponible 7j/7 pour toutes vos questions / دعم متاح على مدار الأسبوع لجميع استفساراتك"
      />
    </div>
  </div>
);

export default FeatureSection;
