import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductById,
  fetchMostViewedProducts,
} from "../../store/productSlice";
import { Loading } from "react-daisyui";
import DOMPurify from "dompurify";
import { ChevronLeft, ChevronRight } from "lucide-react";
import ReactPlayer from "react-player/lazy";

import ProductInfo from "../../components/product/product_page/ProductInfo";
import FeatureSection from "../../components/product/product_page/FeatureSection";
import RelatedProducts from "../../components/product/product_page/RelatedProducts";
import FloatingButton from "../../components/product/product_page/FloatingButton";
import ErrorMessage from "../../components/product/product_page/ErrorMessage";
import OrderForm from "../../components/order/OrderForm";

const GifPlayer = lazy(() => import("react-gif-player"));

const ProductPage = () => {
  const { readableId } = useParams();
  const dispatch = useDispatch();
  const {
    currentProduct: product,
    wilayasWithPricing,
    loading,
    error,
    mostViewedProducts,
    loadingMostViewed,
    errorMostViewed,
  } = useSelector((state) => state.products);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsInitialLoading(true);
      await Promise.all([
        dispatch(fetchProductById(readableId)),
        dispatch(fetchMostViewedProducts()),
      ]);
      setIsInitialLoading(false);
    };

    fetchData();
  }, [dispatch, readableId]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? (product.mainImages?.length || 1) - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === (product.mainImages?.length || 1) - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClickImage = (index) => {
    setCurrentImageIndex(index);
  };

  if (isInitialLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
        <Loading size="lg" />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={`Erreur / خطأ: ${error}`} />;
  }

  if (!product) {
    return <ErrorMessage message="Produit non trouvé / المنتج غير موجود" />;
  }

  const sanitizeConfig = {
    ADD_TAGS: ["em", "strong", "u"],
    ADD_ATTR: ["style"],
  };

  const sanitizedDescription = DOMPurify.sanitize(
    product.description,
    sanitizeConfig
  );

  const LazyImage = ({ src, alt, className }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
      <div className={`relative ${className}`}>
        {!isLoaded && (
          <div className="absolute inset-0 bg-gray-200 animate-pulse flex items-center justify-center">
            <svg
              className="w-12 h-12 text-gray-400 animate-spin"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        <img
          src={src}
          alt={alt}
          className={`w-full h-auto object-contain transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
          onLoad={() => setIsLoaded(true)}
          loading="lazy"
        />
      </div>
    );
  };

  const renderAdditionalMedia = () => {
    const mediaTypes = ["descriptionImages", "gifs", "videos"];
    const maxLength = Math.max(
      ...mediaTypes.map((type) => product[type]?.length || 0)
    );
    const media = [];

    for (let i = 0; i < maxLength; i++) {
      mediaTypes.forEach((type) => {
        if (product[type] && product[type][i]) {
          media.push({ type, content: product[type][i] });
        }
      });
    }

    return media.map((item, index) => {
      switch (item.type) {
        case "descriptionImages":
          return (
            <LazyImage
              key={`desc-img-${index}`}
              src={`${item.content.imageLink}`}
              alt={`Description ${index + 1}`}
              className="w-full h-auto my-4"
            />
          );
        case "gifs":
          return (
            <Suspense
              key={`gif-${index}`}
              fallback={
                <div className="w-full h-64 bg-gray-200 animate-pulse flex items-center justify-center">
                  <svg
                    className="w-12 h-12 text-gray-400 animate-spin"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              }
            >
              <GifPlayer
                gif={item.content.gifLink}
                autoplay={true}
                className="w-full h-auto object-contain my-4"
              />
            </Suspense>
          );
        case "videos":
          return (
            <ReactPlayer
              key={`video-${index}`}
              url={item.content.videoLink}
              controls
              width="100%"
              height="auto"
              className="my-4"
              light={true}
              playIcon={
                <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
                  <svg
                    className="w-8 h-8 text-primary"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </div>
              }
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-blue-50 to-purple-50">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="relative overflow-hidden rounded-lg">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(-${currentImageIndex * 100}%)`,
              }}
            >
              {product.mainImages &&
                product.mainImages.map((image, index) => (
                  <div key={index} className="w-full flex-shrink-0">
                    <LazyImage
                      src={image.imageLink}
                      alt={`Product ${index + 1}`}
                      className="max-h-[70vh]"
                    />
                  </div>
                ))}
            </div>
            {product.mainImages && product.mainImages.length > 1 && (
              <>
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
                  onClick={handlePrevImage}
                >
                  <ChevronLeft size={24} />
                </button>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
                  onClick={handleNextImage}
                >
                  <ChevronRight size={24} />
                </button>
              </>
            )}
          </div>
          <div className="flex justify-center space-x-2 overflow-x-auto py-2">
            {product.mainImages &&
              product.mainImages.map((image, index) => (
                <div
                  key={index}
                  className={`w-20 h-20 flex-shrink-0 rounded-md overflow-hidden cursor-pointer ${
                    currentImageIndex === index
                      ? "ring-2 ring-primary"
                      : "hover:ring-2 hover:ring-gray-300"
                  }`}
                >
                  <LazyImage
                    src={`${image.imageLink}`}
                    alt={`Produit ${index + 1} / المنتج ${index + 1}`}
                    className="w-full h-full"
                    onClick={() => handleClickImage(index)}
                  />
                </div>
              ))}
          </div>

          <div className="hidden lg:block">{renderAdditionalMedia()}</div>
        </div>
        <div className="space-y-6">
          <ProductInfo
            product={product}
            sanitizedDescription={sanitizedDescription}
          />
          <div id="orderFormSection">
            <OrderForm
              product={product}
              wilayasWithPricing={wilayasWithPricing}
            />
          </div>
        </div>
      </div>

      <div className="lg:hidden mt-8">{renderAdditionalMedia()}</div>

      <FeatureSection />

      <RelatedProducts
        products={mostViewedProducts}
        loading={loadingMostViewed}
        error={errorMostViewed}
      />

      <FloatingButton targetId="orderFormSection" />
    </div>
  );
};

export default ProductPage;
