import React from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-daisyui";
import { Edit, EyeOff } from "lucide-react";

const ProductCard = ({ product, isLoggedIn }) => {
  return (
    <Card className="bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300 relative">
      <Link to={`/p/${product.readableId}`}>
        <figure>
          <img
            src={product.imageLink}
            alt={`Product ${product.readableId}`}
            className="w-full h-48 object-cover"
          />
        </figure>
      </Link>
      <Card.Body className="p-4">
        <Card.Title className="text-xl font-bold">
          {product.price} DA
        </Card.Title>
        <p className="text-gray-600 truncate">{product.name}</p>
        <Link to={`/p/${product.readableId}`} className="w-full">
          <Button color="primary" className="w-full">
            Voir le produit
          </Button>
        </Link>
        {isLoggedIn && (
          <div className="card-actions justify-end mt-2">
            <Link
              to={`/admin/products/edit/${product.readableId}`}
              className="absolute top-2 right-2 z-10"
            >
              <Button color="info" size="sm" className="font-bold">
                <Edit size={18} className="mr-1" />
                Edit
              </Button>
            </Link>
            <Link to={`/hide/${product.id}`}>
              <Button color="error" size="sm">
                <EyeOff size={18} />
              </Button>
            </Link>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
