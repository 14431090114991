import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Loading, Button } from "react-daisyui";
import { fetchOrder, downloadInvoice } from "../../store/orderSlice";

const ConfirmationPage = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const {
    currentOrder,
    imageLink,
    color,
    ProductReadablId,
    productPrice,
    loading,
    error,
  } = useSelector((state) => state.orders);

  const handleDownloadInvoice = (orderId) => {
    dispatch(downloadInvoice(orderId));
  };

  useEffect(() => {
    if (orderId) {
      dispatch(fetchOrder(orderId));
    }
  }, [dispatch, orderId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loading size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-error shadow-lg">
        <div>
          <span>Error: {error}</span>
        </div>
      </div>
    );
  }

  if (!currentOrder) {
    return (
      <div className="alert alert-info shadow-lg">
        <div>
          <span>Order not found or still loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-4xl font-bold mb-6 text-center">
        Order Confirmation
      </h1>
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-2xl mb-4">
            Thank you for your order!
          </h2>
          <p className="text-lg font-semibold mb-6">
            We will call you to confirm your order as soon as possible. / سنتصل
            بك لتأكيد طلبك في أقرب وقت ممكن.
          </p>

          <div className="divider"></div>
          <div className="mt-8 text-center">
            <Button
              color="primary"
              onClick={() => handleDownloadInvoice(currentOrder.id)}
            >
              Télécharger la facture / حمل الفاتورة
            </Button>
          </div>
          <div className="divider"></div>
          <h3 className="text-xl font-semibold mb-4">Order Details</h3>
          <div className="flex flex-col md:flex-row items-center mb-6">
            <img
              src={imageLink}
              alt="Product"
              className="w-24 h-24 object-cover rounded-lg shadow-md md:mr-6 mb-4 md:mb-0"
            />
            <div>
              <p className="text-gray-600">
                N Commande / رقم الطلب :{" "}
                <span className="font-semibold">{ProductReadablId}</span>
              </p>
              <p className="text-gray-600">
                Date :{" "}
                <span className="font-semibold">
                  {new Date(currentOrder.createdAt).toLocaleDateString()}
                </span>
              </p>
              <p className="text-gray-600">Coleur id: {currentOrder.colorId}</p>
              <p className="text-gray-600">
                Prix Produit:{" "}
                <span className="font-semibold">{productPrice} DA</span>
              </p>
            </div>
          </div>
          <div className="divider"></div>
          <h3 className="text-xl font-semibold mb-4">Info</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p className="text-gray-600">
              Quantity:{" "}
              <span className="font-semibold">{currentOrder.quantity}</span>
            </p>
            <p className="text-gray-600">
              Name: <span className="font-semibold">{currentOrder.name}</span>
            </p>
            <p className="text-gray-600">
              Phone:{" "}
              <span className="font-semibold">{currentOrder.phoneNumber}</span>
            </p>
            <p className="text-gray-600">
              Address:{" "}
              <span className="font-semibold">{currentOrder.address}</span>
            </p>
            <p className="text-gray-600">
              Wilaya:{" "}
              <span className="font-semibold">{currentOrder.wilaya}</span>
            </p>
            <p className="text-gray-600">
              Delivery:{" "}
              <span className="font-semibold">
                {currentOrder.deliveryOption}
              </span>
            </p>
          </div>
          <div className="divider"></div>
          <p className="text-2xl font-bold text-right">
            Total: {currentOrder.totalPrice} DA
          </p>
          <div className="divider"></div>
          <div className="mt-8 text-center">
            <Button
              color="primary"
              onClick={() => handleDownloadInvoice(currentOrder.id)}
            >
              Télécharger la facture / حمل الفاتورة
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
