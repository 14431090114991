import React, { useState, useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  DollarSign,
  Package,
  TrendingUp,
  Truck,
  RotateCcw,
  Users,
  Box,
  Calendar,
  Percent,
  HelpCircle,
  FileSpreadsheet,
  Trash2,
} from "lucide-react";
import * as XLSX from "xlsx";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#a4de6c",
];

const InfoTooltip = ({ content }) => (
  <div className="tooltip tooltip-right" data-tip={content}>
    <HelpCircle size={16} className="ml-2 text-info cursor-help" />
  </div>
);

const InputField = ({
  icon: Icon,
  label,
  name,
  value,
  onChange,
  step,
  min,
  max,
  tooltipContent,
}) => (
  <div className="form-control">
    <label className="label">
      <span className="label-text flex items-center">
        <Icon className="mr-2" size={16} />
        {label}
      </span>
    </label>
    <div className="flex items-center">
      <input
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        step={step}
        min={min}
        max={max}
        className="input input-bordered w-full max-w-xs"
      />
      <InfoTooltip content={tooltipContent} />
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg">
        {children}
        <div className="mt-4 flex justify-end">
          <button className="btn btn-outline mr-2" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const PricingCalculator = () => {
  const [inputs, setInputs] = useState({
    costPrice: 2,
    unitsPerDay: 100,
    stockDays: 30,
    adSpendPerDay: 50,
    packagingPerUnit: 0.5,
    shippingPerUnit: 1,
    storagePerUnitPerDay: 0.01,
    returnRate: 5,
    returnShippingCost: 2,
    customerServiceCostPerUnit: 0.5,
    desiredProfitMargin: 30,
    customPrice: 0,
    deadStockPercentage: 5,
  });

  const [results, setResults] = useState({
    recommendedPrice: 0,
    profitMargin: 0,
    breakEvenPoint: 0,
    roi: 0,
    totalCost: 0,
    revenueAtBreakEven: 0,
    actualProfitMargin: 0,
    profitPerUnitDesired: 0,
    profitPerUnitCustom: 0,
    grossProfitMargin: 0,
    netProfitMargin: 0,
    categoryCosts: {},
  });

  const [chartData, setChartData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [priceBreakdown, setPriceBreakdown] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: parseFloat(value) || 0 }));
  };

  const calculatePrice = useMemo(() => {
    const {
      costPrice,
      unitsPerDay,
      stockDays,
      adSpendPerDay,
      packagingPerUnit,
      shippingPerUnit,
      storagePerUnitPerDay,
      returnRate,
      returnShippingCost,
      customerServiceCostPerUnit,
      desiredProfitMargin,
      customPrice,
      deadStockPercentage,
    } = inputs;

    const totalUnits = unitsPerDay * stockDays;
    const deadStockUnits = Math.ceil(totalUnits * (deadStockPercentage / 100));
    const soldUnits = totalUnits - deadStockUnits;

    const totalCostPrice = costPrice * totalUnits;
    const totalAdSpend = adSpendPerDay * stockDays;
    const totalPackaging = packagingPerUnit * soldUnits;
    const totalShipping = shippingPerUnit * soldUnits;
    const totalStorage = storagePerUnitPerDay * totalUnits * stockDays;
    const totalReturns = (returnRate / 100) * soldUnits * returnShippingCost;
    //totaCost
    const totalCustomerService = customerServiceCostPerUnit * soldUnits;
    const deadStockCost = deadStockUnits * costPrice;

    const totalCost =
      totalCostPrice +
      totalAdSpend +
      totalPackaging +
      totalShipping +
      totalStorage +
      totalReturns +
      totalCustomerService +
      deadStockCost;

    const costPerUnit = totalCost / soldUnits;

    // Calculate recommended price using the desired profit margin
    const markup = 1 / (1 - desiredProfitMargin / 100);
    const recommendedPrice = costPerUnit * markup;

    const breakEvenPoint = totalCost / recommendedPrice;
    const roi = ((recommendedPrice * soldUnits - totalCost) / totalCost) * 100;

    const actualProfitMargin =
      customPrice > 0 ? ((customPrice - costPerUnit) / customPrice) * 100 : 0;

    const profitPerUnitDesired = recommendedPrice - costPerUnit;
    const profitPerUnitCustom = customPrice - costPerUnit;

    const grossProfitMargin =
      ((recommendedPrice - costPrice) / recommendedPrice) * 100;
    const netProfitMargin =
      ((recommendedPrice - costPerUnit) / recommendedPrice) * 100;

    const categoryCosts = {
      productCost: totalCostPrice / soldUnits,
      marketingCost: totalAdSpend / soldUnits,
      packagingCost: totalPackaging / soldUnits,
      shippingCost: totalShipping / soldUnits,
      storageCost: totalStorage / soldUnits,
      returnsCost: totalReturns / soldUnits,
      customerServiceCost: totalCustomerService / soldUnits,
      deadStockCost: deadStockCost / soldUnits,
    };

    // Detailed price breakdown
    const breakdown = {
      costPerUnit: costPerUnit.toFixed(4),
      desiredProfitMargin: desiredProfitMargin.toFixed(2) + "%",
      markup: markup.toFixed(4),
      recommendedPrice: recommendedPrice.toFixed(4),
      profitPerUnit: profitPerUnitDesired.toFixed(4),
    };

    setPriceBreakdown(breakdown);

    const newChartData = [];
    for (let i = 0; i <= stockDays; i++) {
      const unitsSold = Math.min(i * unitsPerDay, soldUnits);
      const revenue = unitsSold * recommendedPrice;
      const cost =
        costPrice * unitsSold +
        i * adSpendPerDay +
        packagingPerUnit * unitsSold +
        shippingPerUnit * unitsSold +
        storagePerUnitPerDay * totalUnits * i +
        (returnRate / 100) * unitsSold * (costPrice + returnShippingCost) +
        customerServiceCostPerUnit * unitsSold +
        (i === stockDays ? deadStockCost : 0);
      const profit = revenue - cost;
      const actualRevenue = unitsSold * customPrice;
      const actualProfit = actualRevenue - cost;
      newChartData.push({
        day: i,
        revenue,
        cost,
        profit,
        actualRevenue,
        actualProfit,
        desiredMargin: desiredProfitMargin,
        actualMargin: actualProfitMargin,
      });
    }
    setChartData(newChartData);

    return {
      recommendedPrice: recommendedPrice.toFixed(4),
      profitMargin: desiredProfitMargin.toFixed(2),
      breakEvenPoint: breakEvenPoint.toFixed(2),
      roi: roi.toFixed(2),
      totalCost: totalCost.toFixed(2),
      revenueAtBreakEven: (breakEvenPoint * recommendedPrice).toFixed(2),
      actualProfitMargin: actualProfitMargin.toFixed(2),
      profitPerUnitDesired: profitPerUnitDesired.toFixed(4),
      profitPerUnitCustom: profitPerUnitCustom.toFixed(4),
      grossProfitMargin: grossProfitMargin.toFixed(2),
      netProfitMargin: netProfitMargin.toFixed(2),
      categoryCosts: Object.fromEntries(
        Object.entries(categoryCosts).map(([key, value]) => [
          key,
          value.toFixed(4),
        ])
      ),
    };
  }, [inputs]);

  const handleExcelExport = () => {
    setIsModalOpen(true);
  };

  const confirmExcelExport = () => {
    const wb = XLSX.utils.book_new();

    // Inputs sheet
    const inputsData = Object.entries(inputs).map(([key, value]) => [
      key,
      value,
    ]);
    const inputsSheet = XLSX.utils.aoa_to_sheet([
      ["Input", "Value"],
      ...inputsData,
    ]);
    XLSX.utils.book_append_sheet(wb, inputsSheet, "Inputs");

    // Results sheet
    const resultsData = [
      ...Object.entries(results).filter(([key]) => key !== "categoryCosts"),
      ["Category Costs", ""],
      ...Object.entries(results.categoryCosts).map(([key, value]) => [
        `  ${key}`,
        value,
      ]),
    ];
    const resultsSheet = XLSX.utils.aoa_to_sheet([
      ["Metric", "Value"],
      ...resultsData,
    ]);
    XLSX.utils.book_append_sheet(wb, resultsSheet, "Results");

    // Chart data sheet
    const chartDataSheet = XLSX.utils.json_to_sheet(chartData);
    XLSX.utils.book_append_sheet(wb, chartDataSheet, "Chart Data");

    XLSX.writeFile(wb, `${productName}_Pricing_Analysis.xlsx`);
    setIsModalOpen(false);
    setProductName("");
  };

  return (
    <div className="container mx-auto p-4 space-y-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Comprehensive Product Pricing Calculator
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Product Costs</h2>
            <InputField
              icon={DollarSign}
              label="Cost Price per Unit"
              name="costPrice"
              value={inputs.costPrice}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="The price you pay to acquire or produce one unit of your product"
            />
            <InputField
              icon={Package}
              label="Packaging Cost per Unit"
              name="packagingPerUnit"
              value={inputs.packagingPerUnit}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="The cost of packaging materials for one unit"
            />
            <InputField
              icon={Trash2}
              label="Dead Stock Percentage"
              name="deadStockPercentage"
              value={inputs.deadStockPercentage}
              onChange={handleInputChange}
              step="0.1"
              min="0"
              max="100"
              tooltipContent="The percentage of inventory that remains unsold"
            />
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Sales and Inventory</h2>
            <InputField
              icon={Package}
              label="Units Sold per Day"
              name="unitsPerDay"
              value={inputs.unitsPerDay}
              onChange={handleInputChange}
              step="1"
              min="0"
              tooltipContent="The average number of units you expect to sell each day"
            />
            <InputField
              icon={Calendar}
              label="Days of Stock"
              name="stockDays"
              value={inputs.stockDays}
              onChange={handleInputChange}
              step="1"
              min="1"
              tooltipContent="The number of days you want to keep inventory for"
            />
            <InputField
              icon={Box}
              label="Storage Cost per Unit per Day"
              name="storagePerUnitPerDay"
              value={inputs.storagePerUnitPerDay}
              onChange={handleInputChange}
              step="0.001"
              min="0"
              tooltipContent="The daily cost to store one unit of your product"
            />
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Shipping and Returns</h2>
            <InputField
              icon={Truck}
              label="Shipping Cost per Unit"
              name="shippingPerUnit"
              value={inputs.shippingPerUnit}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="The average cost to ship one unit to a customer"
            />
            <InputField
              icon={Percent}
              label="Return Rate (%)"
              name="returnRate"
              value={inputs.returnRate}
              onChange={handleInputChange}
              step="0.1"
              min="0"
              max="100"
              tooltipContent="The percentage of sold units that are returned"
            />
            <InputField
              icon={RotateCcw}
              label="Return Shipping Cost"
              name="returnShippingCost"
              value={inputs.returnShippingCost}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="The cost to ship a returned item back to you"
            />
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Marketing and Customer Service</h2>
            <InputField
              icon={TrendingUp}
              label="Daily Ad Spend"
              name="adSpendPerDay"
              value={inputs.adSpendPerDay}
              onChange={handleInputChange}
              step="1"
              min="0"
              tooltipContent="Your average daily budget for advertising"
            />
            <InputField
              icon={Users}
              label="Customer Service Cost per Unit"
              name="customerServiceCostPerUnit"
              value={inputs.customerServiceCostPerUnit}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="The average cost of customer service per unit sold"
            />
          </div>
        </div>

        <div className="card bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">Pricing Strategy</h2>
            <InputField
              icon={Percent}
              label="Desired Profit Margin (%)"
              name="desiredProfitMargin"
              value={inputs.desiredProfitMargin}
              onChange={handleInputChange}
              step="1"
              min="0"
              max="100"
              tooltipContent="The profit margin you aim to achieve"
            />
            <InputField
              icon={DollarSign}
              label="Your Custom Price"
              name="customPrice"
              value={inputs.customPrice}
              onChange={handleInputChange}
              step="0.01"
              min="0"
              tooltipContent="Enter your own price to see how it affects your profit margin"
            />
          </div>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <div className="flex justify-between items-center mb-6">
            <h2 className="card-title text-2xl">Pricing Analysis Results</h2>
            <button className="btn btn-primary" onClick={handleExcelExport}>
              <FileSpreadsheet className="mr-2" />
              Export to Excel
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">
                Recommended Pricing
              </h3>
              <ul className="space-y-2">
                <li>
                  <span className="font-medium">Recommended Price:</span> DZD
                  {calculatePrice.recommendedPrice}
                </li>
                <li>
                  <span className="font-medium">Desired Profit Margin:</span>{" "}
                  {calculatePrice.profitMargin}%
                </li>
                <li>
                  <span className="font-medium">
                    Profit per Unit (Desired):
                  </span>{" "}
                  DZD {calculatePrice.profitPerUnitDesired}
                </li>
                <li>
                  <span className="font-medium">Break-even Point:</span>{" "}
                  {calculatePrice.breakEvenPoint} units
                </li>
                <li>
                  <span className="font-medium">Revenue at Break-even:</span>{" "}
                  DZD {calculatePrice.revenueAtBreakEven}
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">Custom Pricing</h3>
              <ul className="space-y-2">
                <li>
                  <span className="font-medium">Custom Price:</span> DZD{" "}
                  {inputs.customPrice}
                </li>
                <li>
                  <span className="font-medium">Actual Profit Margin:</span>{" "}
                  {calculatePrice.actualProfitMargin}%
                </li>
                <li>
                  <span className="font-medium">Profit per Unit (Custom):</span>{" "}
                  DZD {calculatePrice.profitPerUnitCustom}
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">
                Cost Breakdown (per Unit)
              </h3>
              <ul className="space-y-2">
                {Object.entries(calculatePrice.categoryCosts).map(
                  ([category, cost]) => (
                    <li key={category}>
                      <span className="font-medium">{category}:</span> DZD{" "}
                      {cost}
                    </li>
                  )
                )}
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">Overall Financials</h3>
              <ul className="space-y-2">
                <li>
                  <span className="font-medium">Total Cost (All Units):</span>{" "}
                  DZD {calculatePrice.totalCost}
                </li>
                <li>
                  <span className="font-medium">ROI:</span> {calculatePrice.roi}
                  %
                </li>
                <li>
                  <span className="font-medium">Gross Profit Margin:</span>{" "}
                  {calculatePrice.grossProfitMargin}%
                </li>
                <li>
                  <span className="font-medium">Net Profit Margin:</span>{" "}
                  {calculatePrice.netProfitMargin}%
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">
              Recommended Price Calculation Breakdown
            </h3>
            <ul className="space-y-2">
              <li>
                <span className="font-medium">Total Cost per Unit: </span> DZD{" "}
                {priceBreakdown.costPerUnit}
              </li>
              <li>
                <span className="font-medium">Desired Profit Margin:</span>{" "}
                {priceBreakdown.desiredProfitMargin}
              </li>
              <li>
                <span className="font-medium">Markup Factor:</span>{" "}
                {priceBreakdown.markup}
              </li>
              <li>
                <span className="font-medium">Recommended Price:</span> DZD{" "}
                {priceBreakdown.recommendedPrice}
              </li>
              <li>
                <span className="font-medium">Profit per Unit:</span> DZD{" "}
                {priceBreakdown.profitPerUnit}
              </li>
            </ul>
            <p className="mt-4 text-sm text-gray-600">
              <span className="font-medium">Formula:</span> Recommended Price =
              Total Cost per Unit / (1 - Desired Profit Margin)
            </p>
          </div>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Cost Structure</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={Object.entries(calculatePrice.categoryCosts).map(
                  ([name, value]) => ({
                    name,
                    value: parseFloat(value),
                  })
                )}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {Object.entries(calculatePrice.categoryCosts).map(
                  (entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  )
                )}
              </Pie>
              <ChartTooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Profit Projection</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="day"
                label={{
                  value: "Days",
                  position: "insideBottomRight",
                  offset: -10,
                }}
              />
              <YAxis
                label={{
                  value: "Amount ( DZD )",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <ChartTooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="revenue"
                name="Revenue (Recommended Price)"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="actualRevenue"
                name="Revenue (Custom Price)"
                stroke="#82ca9d"
              />
              <Line
                type="monotone"
                dataKey="cost"
                name="Cost"
                stroke="#ff7300"
              />
              <Line
                type="monotone"
                dataKey="profit"
                name="Profit (Recommended Price)"
                stroke="#ffc658"
              />
              <Line
                type="monotone"
                dataKey="actualProfit"
                name="Profit (Custom Price)"
                stroke="#a4de6c"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Margin Comparison</h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="day"
                label={{
                  value: "Days",
                  position: "insideBottomRight",
                  offset: -10,
                }}
              />
              <YAxis
                label={{
                  value: "Margin (%)",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <ChartTooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="desiredMargin"
                name="Desired Margin"
                stroke="#8884d8"
              />
              <Line
                type="monotone"
                dataKey="actualMargin"
                name="Actual Margin"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmExcelExport}
      >
        <h2 className="text-xl font-bold mb-4">Enter Product Name</h2>
        <input
          type="text"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          className="input input-bordered w-full"
          placeholder="Product Name"
        />
      </Modal>
    </div>
  );
};

export default PricingCalculator;
