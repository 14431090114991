import React from "react";
import { Select, Input, Dropdown } from "react-daisyui";
import { Search, Filter } from "lucide-react";

const OrderFilters = ({
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter,
  dateRange,
  setDateRange,
  setSortBy,
  setSortOrder,
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0 md:space-x-4">
      <div className="flex-1 w-full md:w-auto">
        <div className="relative">
          <Input
            type="text"
            placeholder="Search orders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pr-10"
          />
          <Search
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div>
      </div>
      <div className="flex space-x-2 w-full md:w-auto">
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="w-full md:w-auto"
        >
          <option value="All">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="Delivered">Delivered</option>
          <option value="Canceled">Canceled</option>
          <option value="Returned">Returned</option>
        </Select>
        <Select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
          className="w-full md:w-auto"
        >
          <option value="all">All Time</option>
          <option value="day">Last 24 Hours</option>
          <option value="week">Last 7 Days</option>
          <option value="month">Last 30 Days</option>
        </Select>
        <Dropdown>
          <Dropdown.Toggle color="ghost">
            <Filter size={20} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-52">
            <Dropdown.Item
              onClick={() => {
                setSortBy("date");
                setSortOrder("desc");
              }}
            >
              Newest First
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setSortBy("date");
                setSortOrder("asc");
              }}
            >
              Oldest First
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setSortBy("price");
                setSortOrder("desc");
              }}
            >
              Highest Price
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setSortBy("price");
                setSortOrder("asc");
              }}
            >
              Lowest Price
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default OrderFilters;
