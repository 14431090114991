import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, clearError } from "../store/productSlice";
import ProductCard from "../components/product/ProductCard";
import { Loading, Button } from "react-daisyui";
import InfiniteScroll from "react-infinite-scroll-component";
import logo from "../assets/logo.png";

const HomePage = () => {
  const dispatch = useDispatch();
  const {
    list: products,
    loading,
    error,
    totalPages,
    totalItems,
  } = useSelector((state) => state.products);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchProducts({ page: 1, limit: 12 })).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const loadMoreProducts = () => {
    if (page < totalPages && !loading) {
      const nextPage = page + 1;
      dispatch(fetchProducts({ page: nextPage, limit: 12 }));
      setPage(nextPage);
    }
  };

  const hasMore = products.length < totalItems;

  if (error) {
    return (
      <div className="alert alert-error shadow-lg max-w-md mx-auto mt-8">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Erreur / خطأ: {error}</span>
        </div>
      </div>
    );
  }

  if (initialLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-purple-100">
        <img
          src={logo}
          alt="Algi-Shop Logo"
          className="w-32 h-32 mb-6 rounded-full shadow-lg animate-pulse"
        />
        <Loading size="lg" />
        <p className="mt-4 text-lg font-semibold text-gray-600">
          Chargement des produits... / جاري تحميل المنتجات...
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-blue-50 to-purple-50">
      <div className="text-center mb-12">
        <img
          src={logo}
          alt="Algi-Shop Logo"
          className="w-32 h-32 mx-auto mb-6 rounded-full shadow-lg"
        />
        <h1 className="text-4xl font-bold text-[#2185d0] mb-2">
          ALGI-SHOP.COM
        </h1>
        <p className="text-xl text-gray-600">
          Votre destination shopping en ligne / وجهتك للتسوق عبر الإنترنت
        </p>
      </div>
      <div className="mb-8 text-center">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Nos Produits / منتجاتنا
        </h2>
      </div>
      <InfiniteScroll
        dataLength={products.length}
        next={loadMoreProducts}
        hasMore={hasMore}
        loader={
          loading && (
            <div className="flex justify-center items-center py-4">
              <Loading size="lg" />
            </div>
          )
        }
        endMessage={
          <p className="text-center text-gray-500 py-4">
            Plus de produits à charger / لا مزيد من المنتجات للتحميل
          </p>
        }
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {products.length === 0 ? (
            <div className="col-span-full text-center text-gray-500">
              <p className="text-xl font-semibold">
                Aucun produit trouvé / لم يتم العثور على منتجات
              </p>
            </div>
          ) : (
            products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                isLoggedIn={isLoggedIn}
              />
            ))
          )}
        </div>
      </InfiniteScroll>
      {hasMore && (
        <div className="text-center mt-8">
          <Button color="primary" onClick={loadMoreProducts} disabled={loading}>
            {loading
              ? "Chargement... / جار التحميل..."
              : "Charger plus de produits / تحميل المزيد من المنتجات"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomePage;
