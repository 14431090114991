import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#2185d0] text-white p-4 mt-8">
      <div className="container mx-auto text-center">
        <p>
          &copy; 2024 ALGI SHOP. All rights reserved. Contact us at{" "}
          <a href="mailto:support@algi-shop.com">support@algi-shop.com</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
