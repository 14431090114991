import React from "react";
import { Link } from "react-router-dom";
import { Button, Loading } from "react-daisyui";
import ErrorMessage from "./ErrorMessage";

const ProductCard = ({ product }) => (
  <div className="bg-white p-4 rounded-lg shadow-md transition duration-300 hover:shadow-xl">
    <div className="w-full h-40 bg-gray-200 rounded-md mb-4 overflow-hidden">
      {product.imageLink ? (
        <img
          src={product.imageLink}
          alt={product.name}
          className="w-full h-full object-cover"
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-gray-500">
          No image available
        </div>
      )}
    </div>
    <h3 className="font-semibold mb-2 text-sm h-10 overflow-hidden">
      {product.name}
    </h3>
    <div className="flex justify-between items-center">
      <span className="font-bold">{product.price} DA</span>
      <Link to={`/product/${product.readableId}`}>
        <Button size="sm" color="primary">
          Voir / عرض
        </Button>
      </Link>
    </div>
  </div>
);

const RelatedProducts = ({ products, loading, error }) => (
  <div className="mt-12">
    <h2 className="text-2xl font-bold mb-6 text-center">
      Favoris des clients / المفضلة لدى العملاء
    </h2>
    {loading ? (
      <Loading size="lg" />
    ) : error ? (
      <ErrorMessage message={`Erreur / خطأ: ${error}`} />
    ) : products.length === 0 ? (
      <p>Aucun produit favori disponible / لا توجد منتجات مفضلة متاحة</p>
    ) : (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    )}
  </div>
);

export default RelatedProducts;
