import axios from "axios";

const API_URL = "/api";
// const API_URL = "http://localhost:8000/api";

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const productService = {
  getAll: (page = 1, limit = 12) =>
    api.get(`/products?page=${page}&limit=${limit}`),
  getById: (readableId) => api.get(`/products/${readableId}`),
  add: (productData) =>
    api.post("/products", productData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (productData) =>
    api.post(`/products/edit/${productData.id}`, productData),
  hide: (id) => api.delete(`/products/${id}`),
  // /view/:id
  view: (id) => api.get(`/products/view/${id}`),
  // /getMostViewed
  getMostViewed: () => api.get("/products/mostviewed"),
};

export const orderService = {
  create: (orderData) => api.post("/orders", orderData),
  getAll: () => api.get("/orders"),
  downloadInvoice: (id) =>
    api.get(`/orders/download/${id}`, { responseType: "blob" }),
  //fetch order
  getOrder: (id) => api.get(`/orders/one/${id}`),
  generateExcel: () =>
    api.get("/orders/excel", {
      responseType: "arraybuffer",
    }),
  updateStatus: (id, status) => api.put(`/orders/update/${id}`, status),
};

export const adminService = {
  login: (credentials) => api.post("/admin/login", credentials),
  getDashboard: () => api.get("/admin/dashboard"),
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page or dispatch a logout action
      console.log("Unauthorized, redirecting to login");
    }
    return Promise.reject(error);
  }
);

export default api;
