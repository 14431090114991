import React from "react";
import { Link } from "react-router-dom";
import { Instagram } from "lucide-react"; // Import the Instagram icon
import logo from "../../assets/logo.png"; // Update this path

const Navbar = () => {
  return (
    <nav className="bg-[#2185d0] text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Algi-Shop Logo" className="h-8 w-auto mr-2" />
          <span className="text-xl font-bold">ALGI-SHOP.COM</span>
        </Link>
        <a
          href="https://www.instagram.com/algishopcom/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-200 transition-colors duration-200"
        >
          <Instagram size={24} />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
