import React from "react";
import { Star, Package, AlertTriangle } from "lucide-react";
import { Badge } from "react-daisyui";

const ProductInfo = ({ product, sanitizedDescription }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">{product.name}</h1>

      <div className="flex items-center space-x-2 mb-4">
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <Star
              key={i}
              size={20}
              className="text-yellow-400"
              fill="currentColor"
            />
          ))}
        </div>

        {/* <span className="text-sm text-gray-600">()</span> */}
      </div>
      <div className="divider" />

      <div className="flex space-x-4 mb-4">
        <div className="flex items-center space-x-2 px-3 py-1 bg-green-100 text-green-800 rounded-full border border-green-300">
          <Package size={16} />
          <span className="text-sm">En stock / متوفر</span>
        </div>
        <div className="flex items-center space-x-2 px-3 py-1 bg-red-100 text-blue-800 rounded-full border border-red-100">
          <AlertTriangle size={16} />
          <span className="text-sm">Offre limitée / عرض محدود</span>
        </div>
      </div>
      <div className="divider" />

      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        dir="auto" // This will automatically set the text direction
        // lang="ar" // Specify the language as Arabic
      />
    </div>
  );
};

export default ProductInfo;
