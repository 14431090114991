import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../store/orderSlice";
import { Input, Select, Button, Loading } from "react-daisyui";
import {
  Plus,
  Minus,
  User,
  Phone,
  MapPin,
  Truck,
  ShieldCheck,
  Clock,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { sha256 } from "js-sha256";
import Cookies from "js-cookie";

const API_VERSION = "v20.0";
const PIXEL_ID = "977111197432239";
const TOKEN =
  "EAAMaOjoNCHIBOxnPQma5mkd39G3YCJwc24C1etZBDAZCIv4GVbWup0qw33qX8OnQ0MutJCwDW29FLROZCJ9KvXVWl6T6Hv8juGfZADpujZADBQ20tZBZCbdKemkKbzqsp3Es4sfysOMObS3HEJhZBi84fQjdpEdqf8ZCO3ACC0qB4UwBcMsZBGZBb6Cuj9pWgMAVwmYvwZDZD";

const OrderForm = ({ product, wilayasWithPricing }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.orders);
  const [formData, setFormData] = useState({
    quantity: 1,
    selectedColor: "",
    name: "",
    phoneNumber: "",
    address: "",
    wilaya: "",
    deliveryOption: "Livraison à domicile",
    packageChoice: product.choicesSame ? product.choicesSame[0].id : null,
  });
  const [totalPrice, setTotalPrice] = useState(product.price);
  const [optionPrice, setOptionPrice] = useState(0);
  const [deliveryPrices, setDeliveryPrices] = useState({
    domicile: 0,
    stopDesk: 0,
  });
  const [orderCount, setOrderCount] = useState(0);
  const [orderLimitReached, setOrderLimitReached] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const showColors = !(
    product.colors &&
    product.colors.length == 1 &&
    product.colors[0].hexCode === "#000000"
  );
  const showSizes =
    product.sizes && product.sizes.length == 0 && !product.sizes[0] === "0";
  useEffect(() => {
    let basePrice;
    let quantity;

    if (product.modeQuantity === "quantity") {
      quantity = formData.quantity;
      basePrice = product.price * quantity;
    } else if (product.modeQuantity === "choicesSame") {
      const selectedPackage = product.choicesSame.find(
        (option) => option.id === formData.packageChoice
      );
      if (selectedPackage) {
        quantity = selectedPackage.quantity;

        basePrice = product.price * quantity * (1 - selectedPackage.discount);
      } else {
        console.error("Selected package not found");
        basePrice = product.price;
        quantity = 1;
      }
      console.log(quantity);
    }

    if (showColors && !formData.selectedColor) {
      setFormData((prevState) => ({
        ...prevState,
        selectedColor: product.colors[0].id,
      }));
    }

    if (showSizes && !formData.selectedSize) {
      setFormData((prevState) => ({
        ...prevState,
        selectedSize: product.sizes[0],
      }));
    }

    const selectedWilaya = wilayasWithPricing.find(
      (w) => w.id.toString() === formData.wilaya
    );
    const deliveryPrice = selectedWilaya
      ? formData.deliveryOption === "Livraison à domicile"
        ? selectedWilaya.tarifDomicile
        : selectedWilaya.tarifStopDesk
      : 0;

    setTotalPrice(basePrice + deliveryPrice);
    setOptionPrice(deliveryPrice);

    checkOrderLimit();
  }, [formData, product, wilayasWithPricing]);

  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 820);
    }, 2500);

    return () => clearInterval(shakeInterval);
  }, []);

  const checkOrderLimit = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const cookieKey = `orderCount_${currentDate}`;
    const storedOrderCount = parseInt(Cookies.get(cookieKey) || "0");

    setOrderCount(storedOrderCount);
    setOrderLimitReached(storedOrderCount >= 2);
  };

  const incrementOrderCount = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const cookieKey = `orderCount_${currentDate}`;
    const newOrderCount = orderCount + 1;
    Cookies.set(cookieKey, newOrderCount, { expires: 1 });
    setOrderCount(newOrderCount);
    setOrderLimitReached(newOrderCount >= 5);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (orderLimitReached) {
      alert(
        "Vous avez atteint le nombre maximum de commandes pour aujourd'hui. Veuillez réessayer demain. / لقد وصلت إلى الحد الأقصى للطلبات اليوم. يرجى المحاولة مرة أخرى غدًا."
      );
      return;
    }
    if (!formData.selectedColor) {
      alert(" يرجى اختيار لون");
      return;
    }
    try {
      const resultAction = await dispatch(
        createOrder({
          ...formData,
          productId: product.id,
          totalPrice,
          colorId: formData.selectedColor,
          optionPrice,
        })
      );

      if (createOrder.fulfilled.match(resultAction)) {
        incrementOrderCount();

        const newOrder = resultAction.payload.order;
        const eventData = {
          event_name: "Purchase",
          event_time: Math.floor(Date.now() / 1000),
          action_source: "website",
          user_data: {
            ph: [sha256(formData.phoneNumber)],
          },
          custom_data: {
            currency: "DZD",
            value: totalPrice.toString(),
            content_name: product.name,
            content_ids: [product.id],
            content_type: "product",
            contents: [
              {
                id: product.id,
                quantity: formData.quantity,
              },
            ],
          },
        };

        try {
          const response = await axios.post(
            `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events`,
            {
              data: [eventData],
              access_token: TOKEN,
            }
          );
          console.log("Facebook Conversions API response:", response.data);
        } catch (error) {
          console.error(
            "Error sending data to Facebook Conversions API:",
            error
          );
        }

        navigate(`/confirmation/${newOrder.id}`);
      } else {
        console.error("Failed to create order:", resultAction.error);
        alert(
          "Échec de la création de la commande. Veuillez réessayer. / فشل في إنشاء الطلب. يرجى المحاولة مرة أخرى."
        );
      }
    } catch (err) {
      console.error("Error in handleSubmit:", err);
      alert(
        "Une erreur s'est produite. Veuillez réessayer. / حدث خطأ. يرجى المحاولة مرة أخرى."
      );
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center p-4">
        <Loading size="lg" />
      </div>
    );

  if (error)
    return (
      <div className="alert alert-error shadow-lg">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Erreur: {error}</span>
        </div>
      </div>
    );

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white p-6 rounded-lg shadow-md space-y-6 text-gray-800"
    >
      <div className="text-3xl font-bold text-primary mb-4">
        {product.price} DA
      </div>

      <div className="space-y-6">
        {showColors && (
          <div>
            <label className="label font-semibold">اللون</label>
            <div className="flex flex-wrap gap-4 bg-gray-100 p-4 rounded-lg">
              {product.colors.map((color) => (
                <div
                  key={color.id}
                  className={`w-10 h-10 rounded-full cursor-pointer border-2 transition-all duration-300 ${
                    formData.selectedColor === color.id
                      ? "border-primary scale-110"
                      : "border-transparent hover:scale-105"
                  }`}
                  style={{ backgroundColor: color.hexCode }}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      selectedColor: color.id,
                    }))
                  }
                />
              ))}
            </div>
          </div>
        )}

        {showSizes && (
          <div>
            <label className="label font-semibold">الحجم</label>
            <div className="flex flex-wrap gap-2">
              {product.sizes.map((size) => (
                <button
                  key={size}
                  type="button"
                  className={`px-4 py-2 rounded-md transition-all duration-300 ${
                    formData.selectedSize === size
                      ? "bg-primary text-white font-semibold"
                      : "bg-white text-gray-800 border border-gray-300 hover:bg-gray-100"
                  }`}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      selectedSize: size,
                    }))
                  }
                >
                  {size}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <Input
        className="w-full border border-gray-300 bg-white text-gray-800"
        type="text"
        placeholder=" الاسم "
        name="name"
        value={formData.nale}
        onChange={handleChange}
        required
        leftIcon={<User size={18} className="opacity-70" />}
      />

      <Input
        type="tel"
        placeholder=" رقم الهاتف"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        required
        className="w-full border border-gray-300 bg-white text-gray-800"
        leftIcon={<Phone size={18} className="opacity-70" />}
      />

      <Input
        type="text"
        placeholder=" العنوان"
        name="address"
        value={formData.address}
        onChange={handleChange}
        required
        className="w-full border border-gray-300 bg-white text-gray-800"
        leftIcon={<MapPin size={18} className="opacity-70" />}
      />

      <Select
        name="wilaya"
        value={formData.wilaya}
        onChange={handleChange}
        required
        className="select-bordered w-full bg-white text-gray-800 border-gray-300"
      >
        <option value="" disabled>
          اختر ولايتك
        </option>
        {wilayasWithPricing.map((wilaya) => (
          <option key={wilaya.id} value={wilaya.id.toString()}>
            {wilaya.id}. {wilaya.nameAr}
          </option>
        ))}
      </Select>

      <div className="space-y-2 bg-gray-100 p-4 rounded-lg">
        {formData.wilaya &&
          wilayasWithPricing.find(
            (w) => w.id.toString() === formData.wilaya
          ) && (
            <>
              <label className="label cursor-pointer justify-start space-x-2">
                <input
                  type="radio"
                  name="deliveryOption"
                  value="Livraison à domicile"
                  checked={formData.deliveryOption === "Livraison à domicile"}
                  onChange={handleChange}
                  className="radio radio-primary"
                />
                <span className="label-text">
                  التوصيل إلى المنزل (+
                  {
                    wilayasWithPricing.find(
                      (w) => w.id.toString() === formData.wilaya
                    ).tarifDomicile
                  }{" "}
                  DA) |{" "}
                  {
                    wilayasWithPricing.find(
                      (w) => w.id.toString() === formData.wilaya
                    ).delai
                  }{" "}
                  {wilayasWithPricing.find(
                    (w) => w.id.toString() === formData.wilaya
                  ).delai > 1
                    ? "أيام"
                    : "يوم"}
                </span>
              </label>

              <label className="label cursor-pointer justify-start space-x-2">
                <input
                  type="radio"
                  name="deliveryOption"
                  value="Livraison au bureau"
                  checked={formData.deliveryOption === "Livraison au bureau"}
                  onChange={handleChange}
                  className="radio radio-primary"
                />
                <span className="label-text">
                  (yalidine) التوصيل إلى المكتب (+
                  {
                    wilayasWithPricing.find(
                      (w) => w.id.toString() === formData.wilaya
                    ).tarifStopDesk
                  }{" "}
                  DA) |{" "}
                  {
                    wilayasWithPricing.find(
                      (w) => w.id.toString() === formData.wilaya
                    ).delai
                  }{" "}
                  {wilayasWithPricing.find(
                    (w) => w.id.toString() === formData.wilaya
                  ).delai > 1
                    ? "أيام"
                    : "يوم"}
                </span>
              </label>
            </>
          )}
      </div>

      {product.modeQuantity === "choicesSame" && (
        <div className="space-y-4">
          <label className="label font-semibold">اختر الحزمة الخاصة بك</label>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {product.choicesSame.map((option) => (
              <div
                key={option.id}
                className={`p-4 rounded-lg cursor-pointer transition-all duration-300 ${
                  formData.packageChoice === option.id
                    ? "border-2 border-primary scale-105"
                    : "border border-gray-300 hover:border-primary hover:scale-105"
                }`}
                // onClick={() => handlePackageChoice(option.id)}
                onClick={() =>
                  setFormData((prev) => ({
                    ...prev,
                    quantity: option.quantity,
                    packageChoice: option.id,
                  }))
                }
              >
                <h3 className="text-lg font-semibold mb-2 text-right">
                  {option.quantity} {option.quantity > 1 ? "X" : "X"}
                </h3>
                {option.discount > 0 && (
                  <p className="text-sm text-green-600 mb-1 text-right">
                    وفر {option.discount * 100}٪
                  </p>
                )}
                <p className="text-sm text-gray-600 text-right">
                  السعر:{" "}
                  {(
                    product.price *
                    option.quantity *
                    (1 - option.discount)
                  ).toFixed(1)}{" "}
                  دج
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="flex justify-between items-center bg-primary bg-opacity-10 p-4 rounded-lg">
        <span className="text-lg font-bold"> السعر الإجمالي:</span>
        <span className="text-2xl font-bold text-[#4A0E4E]">
          {totalPrice.toFixed(1)} DA
        </span>
      </div>

      <div className="flex items-center space-x-2 sm:space-x-4">
        {product.modeQuantity === "quantity" ? (
          <div className="w-1/3 bg-gray-100 rounded-lg flex items-center justify-between p-1 sm:p-2">
            <button
              type="button"
              className="btn btn-circle btn-sm bg-white text-gray-600 hover:bg-gray-200 border border-gray-300"
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  quantity: Math.max(1, prev.quantity - 1),
                }))
              }
            >
              <Minus size={16} />
            </button>
            <span className="text-base sm:text-xl font-bold">
              {formData.quantity}
            </span>
            <button
              type="button"
              className="btn btn-circle btn-sm bg-white text-gray-600 hover:bg-gray-200 border border-gray-300"
              onClick={() =>
                setFormData((prev) => ({
                  ...prev,
                  quantity: prev.quantity + 1,
                }))
              }
            >
              <Plus size={16} />
            </button>
          </div>
        ) : null}
        <button
          type="submit"
          className={`btn btn-primary flex-grow py-2 sm:py-3 px-4 sm:px-6 text-sm sm:text-base ${
            orderLimitReached
              ? "opacity-50 cursor-not-allowed"
              : isShaking
              ? "animate-shake"
              : ""
          }`}
          disabled={orderLimitReached}
        >
          {orderLimitReached
            ? "Limite atteinte / تم الوصول للحد"
            : "تأكيد الطلب"}
        </button>
      </div>
      {orderLimitReached && (
        <p className="text-center text-sm text-red-500">
          Vous avez atteint la limite de commandes pour aujourd'hui. Veuillez
          réessayer demain. / لقد وصلت إلى الحد الأقصى للطلبات اليوم. يرجى
          المحاولة مرة أخرى غدًا.
        </p>
      )}

      <p className="text-center text-sm text-gray-500">
        Livraison sous 24 à 48 heures / التوصيل خلال 24 إلى 48 ساعة
        <br />
        Nous vous contacterons pour confirmer la commande / سنتصل بك لتأكيد
        الطلب
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
        <FeatureCard
          icon={<Truck size={24} />}
          title="Livraison rapide / توصيل سريع"
          description="Sous 24-48 heures / خلال 24-48 ساعة"
        />
        <FeatureCard
          icon={<ShieldCheck size={24} />}
          title="Garantie qualité / ضمان الجودة"
          description="Satisfait ou remboursé / مضمون أو مسترد"
        />
        <FeatureCard
          icon={<Clock size={24} />}
          title="Support 24/7 / دعم على مدار الساعة"
          description="Toujours à votre service / دائمًا في خدمتك"
        />
      </div>
    </form>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-4 bg-gray-50 rounded-lg">
    <div className="text-primary mb-2">{icon}</div>
    <h5 className="font-semibold text-sm mb-1">{title}</h5>
    <p className="text-xs text-gray-600">{description}</p>
  </div>
);

export default OrderForm;
